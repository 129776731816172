import React, { useEffect, useState } from "react";
import cx from "./index.module.scss";
import pm from "../../../newStyle.module.scss";
import st from "../../../style.module.scss";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from 'react-router-dom';
import { httpRequest } from "../../../Apis/commonApis";
import { Tab, Nav, Row, Col, Accordion } from "react-bootstrap";
import basic from "../../../images/basicInfoTab.svg";
import Checkbox from "../../../components/Forms/Checkbox";
import MultiSelect from "../../../components/MultipleSelect/MultipleSelect";
import uploadFile from "../../../images/iconUpload.svg";
import uploadIcon from "../../../images/uploadIcon.svg";
import SuccessPasswordModal from "../../../components/Modals/SuccessPasswordModal";
import OnOffSwitch from "../../../components/Forms/OnOffSwitch";
import { IoIosCloseCircle } from "react-icons/io";
import crossIcon from '../../../assest/crossRed.svg'

const AddBusinessPage = (props: any) => {
  const { register, handleSubmit, formState: { errors }, setValue, watch } = useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const [icon, setIcon] = React.useState<any>();
  const [businessCat, setBusinessCat] = useState([]);
  const [loading, setLoading] = useState<any>(false);
  const [apiError, setApiError] = useState<any>("")

  const [selectedCategories, setSelectedCategories] = useState<any>([])

  const [message, setMessage] = useState<String>("")
  const [show, setShow] = useState<boolean>(false)
  const handleClose = () => {
    setMessage("")
    setShow(false)
  }

  const [actionArray, setActionArray] = useState<any>([]);

  const [imageagain, setImageAgain] = React.useState<any>(null);
  const [businessData, setBusinessData] = useState<any>([]);
  const [promotionList, setPromotionList] = useState<any>([]);
  const [attribute1List, setAttribute1List] = useState<any>([])
  const [attribute2List, setAttribute2List] = useState<any>([])
  const [attribute3List, setAttribute3List] = useState<any>([]);

  const [isSlider, setIsSlider] = useState<any>(false)

  const [selectedAttribute1, setSelectedAttribute1] = useState<any>([]);
  const [selectedAttribute2, setSelectedAttribute2] = useState<any>([]);
  const [selectedAttribute3, setSelectedAttribute3] = useState<any>([]);

  const [selectedPromotion, setSelectedPromotion] = useState<any>([]);
  const [selectedTag, setSelectedTag] = useState<any>([]);
  const [selectedSearchTag, setSelectedSearchTag] = useState<any>([]);

  const [serviceTagList, setServiceTagList] = useState<any>([])

  const [errorTrig, setErrorTrig] = useState<any>(false)

  const [editData, setEditData] = useState<any>(null)

  const [collectionList, setCollectionList] = useState([])

  async function getCollection() {
    const res = await httpRequest(`collection_cms`, "GET", null, "json");
    if (res.status) {
      setCollectionList(res?.data)
    }
  }

  async function getCms() {
    const res = await httpRequest(`section_cms?_id=${location?.state?.cmsId}`, "GET", null, "json");
    if (res.status) {
      setEditData(res?.data[0])
    }
  }

  async function getAttribute1() {
    const res = await httpRequest(`attribute1`, "GET", null, "json");
    if (res.status) {
      setAttribute1List(res?.data)
    }
  }
  async function getAttribute2() {
    const res = await httpRequest(`attribute2`, "GET", null, "json");
    if (res.status) {
      setAttribute2List(res?.data)
    }
  }
  async function getAttribute3() {
    const res = await httpRequest(`attribute3`, "GET", null, "json");
    if (res.status) {
      setAttribute3List(res?.data)
    }
  }

  useEffect(() => {
    if (editData) {
      setValue("sectionType", editData?.sectionType)
      setValue("sectionName", editData?.sectionName)
      setValue("title", editData?.title);
      setValue("maxCol", editData?.maxCol || '');
      setValue("sequence", editData.sequence);
      setValue("url", editData?.url || "")
      setValue("colorCodePrimary", editData?.colorCodePrimary || "")
      setValue("colorCode", editData?.colorCode || "")
      setValue("bgCode", editData?.bgCode || "")
      setValue("textCode", editData?.textCode || "")
      setValue("description", editData?.description || "");
      setValue("maxHeight", editData?.maxHeight || "");

      if (editData?.sectionType === "promotion") {
        setSelectedPromotion(editData?.promotion)
      }

      if (editData?.sectionType === "business" || editData?.sectionType === "promotion") {
        const filterObj = editData?.filter?.[0]
        if (filterObj?.attribute1) {
          setSelectedAttribute1(filterObj?.attribute1)
        }

        if (filterObj?.attribute2) {
          setSelectedAttribute2(filterObj?.attribute2)
        }
        if (filterObj?.attribute3) {
          setSelectedAttribute3(filterObj?.attribute3)
        }
        if (filterObj?.tag) {
          setSelectedTag(filterObj?.tag)
        }
        if (filterObj?.category) {
          setSelectedCategories(filterObj?.category)
        }
        if (filterObj?.priority) {
          setValue("priority", filterObj?.priority)
        }


        if (filterObj?.featured) {
          setValue("feature", filterObj?.featured)
        }
        if (filterObj?.trending) {
          setValue("trending", filterObj?.trending)
        }

        if (editData?.sort?.[0]) {
          setValue("sort", editData?.sort?.[0])
        }
      }
      setValue('imageType', editData?.imageType || '')

      if (editData?.sectionType === "search") {
        setSelectedSearchTag(editData?.serviceTags || [])
      }

      if (editData?.action?.length > 0) {
        setActionArray(editData?.action?.map((item: any) => ({ ...item, error: false })))
      } else {
        if (editData?.sectionType === "business" || editData?.sectionType === "promotion") {
          setActionArray([{ sq: 1, businessId: "", businessAccountNo: "", error: false }])
        }

        if (editData?.sectionType === "marquee" || editData?.sectionType === "category") {
          setActionArray([{ sq: 1, categoryId: "", error: false }])
        }

        if (editData?.sectionType === "collection") {
          setActionArray([{ sq: 1, collectionId: "", error: false }])
        }
        if (editData?.sectionType === "banner") {
          setActionArray([{ sq: 1, bannerUrl: "", url: "", error: false }])
        }
      }

      setValue("imageLimit", editData?.imageLimit || "")

      if (editData?.image) {
        setIcon(editData?.image)
      }


      if (editData?.sectionType === "business" || editData?.sectionType === "promotion") {
        setIsSlider(editData?.isSlider || false);
        setValue("maxRows", editData?.maxRows);
      }
    }
  }, [editData, businessData])

  const getBusinessInfo = async () => {
    const res = await httpRequest("get_BusinessInfo", "get", null, "withoutToken");
    if (res?.status == true) {
      setBusinessData(res?.data)
    }
  };

  const getServiceTag = async () => {
    let res = await httpRequest(`get_ServiceTag`, "get", null, "auth");
    if (res?.status) {
      setServiceTagList(res.data)
    }
  }

  useEffect(() => {
    if (location.state?.cmsId) {
      getCms()
    }
  }, [location.state?.cmsId])

  const getbusinessCatagory = async () => {
    const res: any = await httpRequest("get_Catagory", "Get", null, "withoutToken");
    if (res?.status == true) {
      setBusinessCat(res?.data)
    }
  };

  const getPromotion = async () => {
    const res: any = await httpRequest(`getPromotion`, "get", null, null);
    if (res?.status) {
      setPromotionList(res?.data)
    }
  }

  useEffect(() => {
    getbusinessCatagory()
    getBusinessInfo()
    getPromotion()
    getServiceTag()
    getAttribute1()
    getAttribute2()
    getAttribute3()
    getCollection()
  }, [])

  const sectionType = watch("sectionType");
  const priority = watch("priority");
  const featured = watch("feature");
  const trending = watch("trending");

  const handleImageChange = function (e: React.ChangeEvent<HTMLInputElement>) {
    const fileList: any = e.target.files;
    if (!fileList) return;
    let display = URL.createObjectURL(fileList[0]);
    setIcon(display);
    setImageAgain(fileList[0]);
  };
  const filterObj = {
    category: selectedCategories,
    tag: selectedTag,
    attribute1: selectedAttribute1,
    attribute2: selectedAttribute2,
    attribute3: selectedAttribute3,
    priority,
    featured,
    trending
  }

  async function submitHandler(data: any) {
    const formdata = new FormData();
    formdata.append("sectionType", data?.sectionType)
    formdata.append("isActive", JSON.stringify(editData ? editData?.isActive : true))
    formdata.append("sequence", data.sequence)

    formdata.append("imageLimit", data?.imageLimit || "")
    formdata.append("maxHeight", data?.maxHeight || "")

    if (data?.title) {
      formdata.append("title", data?.title);
    }

    if (data?.sectionType === "search") {
      formdata.append("serviceTags", JSON.stringify(selectedSearchTag))
    }

    if (data?.imageType) {
      formdata.append('imageType', data?.imageType)
    }

    if (data?.sectionName) {
      formdata.append("sectionName", data?.sectionName)
    }
    if (data?.url) {
      formdata.append("url", data?.url)
    }

    if (data?.description) {
      formdata.append("description", data?.description)
    }

    if (imageagain) {
      formdata.append("image", imageagain)
    }

    if (data?.colorCodePrimary) {
      formdata.append("colorCodePrimary", data?.colorCodePrimary)
    }
    if (data?.colorCode) {
      formdata.append("colorCode", data?.colorCode)
    }
    if (data?.bgCode) {
      formdata.append("bgCode", data?.bgCode)
    }
    if (data?.textCode) {
      formdata.append("textCode", data?.textCode)
    }

    if (data?.sectionType === "promotion") {
      formdata.append("promotion", JSON.stringify(selectedPromotion));
    } else {
      formdata.append("promotion", JSON.stringify([]));
    }

    if (data?.sectionType === "business" || data?.sectionType === "promotion") {
      formdata.append("filter", JSON.stringify([filterObj]));
      formdata.append("sort", JSON.stringify([data?.sort]));
    } else {
      formdata.append("filter", JSON.stringify([]));
      formdata.append("sort", JSON.stringify([]));
    }

    if (data?.sectionType === "business" || data?.sectionType === "promotion") {
      formdata.append("isSlider", JSON.stringify(isSlider));
      formdata.append("maxRows", data?.maxRows);
      formdata.append("maxCol", data?.maxCol);
    } else {
      formdata.append("isSlider", JSON.stringify(false));
      formdata.append("maxRows", "");
      formdata.append("maxCol", '');
    }

    if (actionArray?.some((item: any) => item?.error)) {
      setErrorTrig(true)
      setTimeout(() => {
        setErrorTrig(false)
      }, 3000)
      return
    }
    const modifiedActionArr = actionArray?.map((item: any) => {
      const { error, ...rest } = item
      return rest
    })

    const filterNonEmptyArray = modifiedActionArr?.filter((item: any) => {
      if (data?.sectionType === "business" || data?.sectionType === "promotion") {
        return item?.businessId !== ""
      }

      if (data?.sectionType === "collection") {
        return item?.collectionId !== ""
      }

      if (data?.sectionType === "marquee" || data?.sectionType === "category") {
        return item?.categoryId !== "";
      }

      if (data?.sectionType === "banner") {
        return item?.bannerUrl !== "";
      }
    })


    formdata.append("action", JSON.stringify(filterNonEmptyArray))

    setLoading(true)
    const path = editData ? `section_cms/${editData?._id}` : "section_cms";
    const method = editData ? "PATCH" : "POST"
    const res = await httpRequest(path, method, formdata, "formdata");

    if (res.status) {
      setApiError("")
      setShow(true)
      setMessage(res?.message)
      setTimeout(() => {
        navigate("/webpages/business-section")
      }, 2000)
    } else {
      setApiError(res?.message)
      setTimeout(() => {
        setApiError("")
      }, 4000)
    }
    setLoading(false)
  }

  useEffect(() => {
    if (!editData || (editData?.sectionType !== sectionType)) {
      if (sectionType === "marquee" || sectionType === "category") {
        setActionArray([{ sq: 1, categoryId: "", error: false }])
      }
      if (sectionType === "business" || sectionType === "promotion") {
        setActionArray([{ sq: 1, businessId: "", businessAccountNo: "", error: false }])
      }

      if (sectionType === "collection") {
        setActionArray([{ sq: 1, collectionId: "", error: false }])
      }
      if (sectionType === "banner") {
        setActionArray([{ sq: 1, bannerUrl: "", url: "", error: false }])
      }
    }

  }, [sectionType]);

  const filterBusiness = businessData?.filter((item: any) => {
    const catCheck = item?.businessCatagoryId?.some((cat: any) => selectedCategories?.length > 0 ? selectedCategories?.some((selCat: any) => selCat?.value === cat?._id) : true);
    const trendingCheck = trending ? item?.trending === trending : true
    const featureCheck = featured ? item?.featured === featured : true
    const priorityCheck = priority ? item?.priority === Number.parseInt(priority) : true;

    const tagCheck = selectedTag?.length > 0 ? item?.searchArray?.some((item: any) => selectedTag.some((tag: any) => tag?.label?.toLocaleLowerCase()?.replaceAll(" ", "") === item?.toLocaleLowerCase()?.replaceAll(" ", ""))) : true;
    const attribute1Check = selectedAttribute1?.length > 0 ? selectedAttribute1?.some((attr: any) => item?.attribute1?.some((data: any) => data ? data?._id === attr?.value : false)) : true
    const attribute2Check = selectedAttribute2?.length > 0 ? selectedAttribute2?.some((attr: any) => item?.attribute2?.some((data: any) => data ? data?._id === attr?.value : false)) : true
    const attribute3Check = selectedAttribute3?.length > 0 ? selectedAttribute3?.some((attr: any) => item?.attribute3?.some((data: any) => data ? data?._id === attr?.value : false)) : true

    return catCheck && trendingCheck && featureCheck && priorityCheck && attribute1Check && attribute2Check && attribute3Check && tagCheck
  })

  useEffect(() => {
    if (actionArray?.length > 0 && (sectionType === "business" || sectionType === "promotion")) {
      const modifiedArr = actionArray?.map((item: any) => {
        if (filterBusiness?.some((business: any) => business?._id === item?.businessId)) {
          return item;
        } else {
          return { ...item, businessId: "", businessAccountNo: "" };
        }
      });

      const isModifiedArrDifferent = modifiedArr.some((item: any, index: number) => item.businessId !== actionArray[index].businessId || item.businessAccountNo !== actionArray[index].businessAccountNo);
      if (isModifiedArrDifferent) {
        setActionArray(modifiedArr);
      }
    }
  }, [filterBusiness]);

  function handleAddRemoveHandler(type: string, index: number, actionType: string = "") {
    let newArray = [...actionArray]
    if (type === "inc") {
      if (actionType === "business") {
        newArray.push({ sq: actionArray?.length + 1, businessId: "", businessAccountNo: "", error: false })
      }
      else if (actionType === "collection") {
        newArray.push({ sq: actionArray?.length + 1, collectionId: "" })
      } else if (actionType === "category") {
        newArray.push({ sq: actionArray?.length + 1, categoryId: "", error: false })
      } else if (actionType === "banner") {
        newArray.push({ sq: actionArray?.length + 1, bannerUrl: "", url: "", error: false })
      }

      setActionArray(newArray)
    } else {
      newArray = newArray.filter((_: any, i: any) => i !== index)
    }
    setActionArray(newArray)
  }

  function handleAddCat(index: number, value: any, type: string = "",) {
    let newArray = [...actionArray];
    const duplicateValue: any = newArray?.some((item: any) => item?.[type] === value);
    if (type === "businessId") {
      newArray[index] = {
        ...newArray[index],
        [type]: value,
        error: duplicateValue ? true : false,
        businessAccountNo: businessData?.find((item: any) => item?._id === value)?.businessAccountNo
      }
    } else {
      newArray[index] = {
        ...newArray[index],
        error: duplicateValue ? true : false,
        [type]: value
      }
    }

    setActionArray(newArray)
  }


  function handleCategory(data: any, type: any) {
    if (type === "category") {
      setSelectedCategories(data)
    }

    if (type === "tag") {
      setSelectedTag(data)
    }
    if (type === "attribute1") {
      setSelectedAttribute1(data)
    }
    if (type === "attribute2") {
      setSelectedAttribute2(data)
    }
    if (type === "attribute3") {
      setSelectedAttribute3(data)
    }

    if (type === "searchTag") {
      setSelectedSearchTag(data)
    }
  }

  function handleSlider(e: any) {
    setIsSlider(e.target.checked)
  }

  async function handleBanner(e: any, i: number) {
    if (e === "delete") {
      let copyActionArray = [...actionArray];
      copyActionArray = copyActionArray?.map((item: any, index: number) => {
        if (index === i) {
          return {
            ...item,
            bannerUrl: ""
          }
        } else {
          return item
        }
      })
      setActionArray(copyActionArray)
      return
    }
    const file = e.target.files[0];
    const formdata = new FormData();
    formdata.append("image", file);
    const response = await httpRequest("getS3Link", "POST", formdata, "formdata");
    if (response.status) {
      let copyActionArray = [...actionArray];
      copyActionArray = copyActionArray?.map((item: any, index: number) => {
        if (index === i) {
          return {
            ...item,
            bannerUrl: response?.data
          }
        } else {
          return item
        }
      })
      setActionArray(copyActionArray)
    }
  }

  return (
    <>
      <SuccessPasswordModal handleClose={handleClose} show={show} message={message} />

      <section className={`${pm.pageWrapper}`} style={{ overflowY: "auto", height: "100%", paddingBottom: "80px" }}>
        <div className={`${pm.pageTitle}`}>
          <div className={`${pm.titleInfo}`}>
            {editData ? `${editData?.sectionType} > ${editData?.sectionName || ""}` : "Add Section"}
          </div>
          <div className={`${pm.rightInfo}`}></div>
        </div>

        <div className={`${pm.tabbingBox}`}>
          <Tab.Container id="left-tabs-example" defaultActiveKey="tabNew1">
            <Nav variant="pills">
              <Nav.Item>
                <Nav.Link eventKey="tabNew1">
                  <img src={basic} alt="info" className={`${pm.default}`} />
                  Details
                </Nav.Link>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="tabNew1">
                <Row>
                  <Col xl={3} lg={4} md={6}>
                    <div className={cx.price_categorySelect}>
                      <div className={pm.formBox}>
                        <label className="form-label">Section Type </label>
                        <select className="form-select" {...register("sectionType", { required: true })}>
                          <option value="">Section Type</option>
                          <option value="business">Business</option>
                          <option value="promotion">Promotion</option>
                          <option value="collection">Collection</option>
                          <option value="marquee">Marquee</option>
                          <option value="search">Search</option>
                          <option value="banner">Banner</option>
                          <option value="category">Category</option>
                        </select>
                        {errors?.sectionType?.type === "required" && (
                          <p className={"errorMessage"}>This field is required</p>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col xl={3} lg={4} md={6}>
                    <div className={cx.price_categorySelect}>
                      <div className={pm.formBox}>
                        <label className="form-label">Section Name</label>
                        <input type="text" className="form-control" placeholder="Section Name" {...register("sectionName", { required: true })} />
                        {errors?.sectionName?.type === "required" && (
                          <p className={"errorMessage"}>This field is required</p>
                        )}
                      </div>
                    </div>
                  </Col>

                  <Col xl={2} lg={2} md={3}>
                    <div className={cx.price_categorySelect}>
                      <div className={pm.formBox}>
                        <label className="form-label">Sequence No.</label>
                        <input type="number" className="form-control" placeholder="Sequence No." {...register("sequence", { required: true })} />
                        {errors?.sequence?.type === "required" && (
                          <p className={"errorMessage"}>This field is required</p>
                        )}
                        {apiError && (
                          <p className={"errorMessage"}>{apiError}</p>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col xl={2} lg={4} md={4} className="px-2 mt-4">
                    <div className={cx.saveButton}>
                      <button className="btn plusBtn" onClick={handleSubmit(submitHandler)}>
                        {loading ? (
                          <div
                            className="spinner-border spinner-border-sm text-light"
                            role="status"
                          ></div>
                        ) : (
                          editData ? "Update" : 'Save'
                        )}
                      </button>
                    </div>
                  </Col>
                </Row>

                {sectionType === "search" && (
                  <Col md={3}>
                    <div className={pm.formBox}>
                      <label className="form-label">Tag </label>
                      <MultiSelect list={serviceTagList?.map((item: any) => ({ label: item?.tags, value: item?._id }))} value={selectedSearchTag} onChange={(data: any) => handleCategory(data, "searchTag")} />
                    </div>
                  </Col>

                )}

                {sectionType !== "search" && <Row className="align-items-end">
                  <Col xl={3} lg={4} md={6} >
                    <div className={`${pm.formBox} position-relative`}>
                      <label className="form-label">Title </label>
                      <input type="text" className="form-control" placeholder="Title" {...register("title", { required: true })} />
                      {errors?.title?.type === "required" && (
                        <p className={"errorMessage position-absolute b-0"}>This field is required</p>
                      )}
                    </div>
                  </Col>

                  {sectionType === "banner" && <>
                    <Col xl={2} lg={3} md={4} >
                      <div className={`${pm.formBox} position-relative`}>
                        <label className="form-label">Maximum Image per frame</label>
                        <select className="form-select" {...register("imageLimit", { required: true })}>
                          <option value="">Maximum Image per frame</option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                        </select>
                        {errors?.imageLimit?.type === "required" && (
                          <p className={"errorMessage position-absolute b-0"}>This field is required</p>
                        )}
                      </div>
                    </Col>
                    <Col xl={2} lg={3} md={4} >
                      <div className={`${pm.formBox} position-relative`}>
                        <label className="form-label">Maximum image heigth (in px)</label>
                        <input className="form-control" {...register("maxHeight")} placeholder="Maximum image heigth" />

                      </div>
                    </Col>

                  </>
                  }

                  {(sectionType === "business" || sectionType === "promotion") && (
                    <>
                      <Col xl={1} lg={1} md={2} >
                        <div className={cx.price_categorySelect}>
                          <div className={`${pm.formBox} position-relative`}>
                            <label className="form-label">Slider</label>
                            <div>
                              <OnOffSwitch checked={isSlider} handleChange={handleSlider} actionType='slider' />
                            </div>
                          </div>
                        </div>
                      </Col>

                      {!isSlider && <Col xl={2} lg={3} md={3}>
                        {/* <div className={cx.price_categorySelect}> */}
                        <div className={`${pm.formBox} position-relative`}>
                          <label className="form-label" htmlFor="Maximum rows">Maximum rows</label>
                          <input type="text" id="Maximum rows" className="form-control" placeholder="Maximum rows" {...register("maxRows")} />
                        </div>
                        {/* </div> */}
                      </Col>}
                      <Col xl={2} lg={2} md={6} >
                        <div className={`${pm.formBox} position-relative`}>
                          <label className="form-label">Maximum Card</label>
                          <select className="form-select" {...register("maxCol",)}>
                            <option value="">Section Type</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                          </select>
                        </div>
                      </Col>
                    </>
                  )}

                  {sectionType === "marquee" && <Col xl={3} lg={2} md={3} >
                    <div className={cx.price_categorySelect}>
                      <div className={`${pm.formBox} position-relative`}>
                        <label className="form-label" htmlFor="url">URL</label>
                        <input type="text" id="url" className="form-control" placeholder="URL" {...register("url", { required: true })} />
                        {errors?.url?.type === "required" && (
                          <p className={"errorMessage position-absolute b-0"}>This field is required</p>
                        )}
                      </div>
                    </div>
                  </Col>}

                  {(sectionType === 'category') && (
                    <>
                      <Col md={2}>
                        <div className={"d-flex gap-2"}>
                          <label className={`${st.radio} mb-4 d-flex gap-2`}>
                            <input type="radio" value={'icon'} {...register("imageType")} />
                            <span className={`${st.checkmark}`}></span>Show Icon
                          </label>
                        </div>
                      </Col>
                      <Col md={2}>
                        <div className={"d-flex gap-2"}>
                          <label className={`${st.radio} mb-4 d-flex gap-2`}>
                            <input type="radio" value={'image'} {...register("imageType")} />
                            <span className={`${st.checkmark}`}></span>Show Image
                          </label>
                        </div>
                      </Col>
                    </>
                  )}

                  {sectionType === "promotion" && (
                    <Col md={3}>
                      <div className={pm.formBox}>
                        <label className="form-label">Promotion Name</label>
                        <MultiSelect list={promotionList?.map((item: any) => ({ label: item?.promotionName, value: item?._id }))} value={selectedPromotion} onChange={(data: any) => setSelectedPromotion(data)} />
                      </div>
                    </Col>

                  )}
                  {/* <Col md={6}>
                      <div className={`${pm.formBox} d-flex gap-3`}>
                        <label className="form-label">Near Me</label>
                        <Checkbox {...register("nearMe")}/>
                      </div>
                    </Col> */}
                </Row>}



                {sectionType && sectionType !== "marquee" && sectionType !== 'category' && sectionType !== "banner" && sectionType !== "search" && sectionType !== "collection" && <>
                  <Row className="mt-2">
                    <Col md={12} lg={10}>
                      <div>
                        <Accordion className="mb-3">
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>Business Filters</Accordion.Header>
                            <Accordion.Body>
                              <Row>
                                <Col md={4}>
                                  <div className={pm.formBox}>
                                    <label className="form-label">Categories </label>
                                    <MultiSelect list={businessCat?.map((item: any) => ({ label: item?.BusinessCategoryName, value: item?._id }))} value={selectedCategories} onChange={(data: any) => handleCategory(data, "category")} />
                                  </div>
                                </Col>
                                <Col md={4}>
                                  <div className={pm.formBox}>
                                    <label className="form-label">Tag </label>
                                    <MultiSelect list={serviceTagList?.map((item: any) => ({ label: item?.tags, value: item?._id }))} value={selectedTag} onChange={(data: any) => handleCategory(data, "tag")} />
                                  </div>
                                </Col>
                                <Col md={4}>
                                  <div className={pm.formBox}>
                                    <label className="form-label">Attribute1 </label>
                                    <MultiSelect list={attribute1List?.map((item: any) => ({ label: item?.name, value: item?._id }))} value={selectedAttribute1} onChange={(data: any) => handleCategory(data, "attribute1")} />
                                  </div>
                                </Col>
                                <Col md={4}>
                                  <div className={pm.formBox}>
                                    <label className="form-label">Attribute2 </label>
                                    <MultiSelect list={attribute2List?.map((item: any) => ({ label: item?.name, value: item?._id }))} value={selectedAttribute2} onChange={(data: any) => handleCategory(data, "attribute2")} />
                                  </div>
                                </Col>
                                <Col md={4}>
                                  <div className={pm.formBox}>
                                    <label className="form-label">Attribute3 </label>
                                    <MultiSelect list={attribute3List?.map((item: any) => ({ label: item?.name, value: item?._id }))} value={selectedAttribute3} onChange={(data: any) => handleCategory(data, "attribute3")} />
                                  </div>
                                </Col>
                                <Col md={4}>
                                  <div className={pm.formBox}>
                                    <label className="form-label">Priority </label>
                                    <select className="form-select" {...register("priority")}>
                                      <option value="">--Select--</option>
                                      {[1, 2, 3, 4, 5]?.map((item: any) => {
                                        return (
                                          <option value={item} key={item}>{item}</option>
                                        )
                                      })}
                                    </select>
                                  </div>
                                </Col>
                              </Row>
                              <Row className="mt-3">
                                <Col md={3}>
                                  <Row>
                                    <Col md={6}>
                                      <div className={`${pm.formBox} d-flex gap-3`}>
                                        <label className="form-label">Featured</label>
                                        <Checkbox register={{ ...register("feature") }} />
                                      </div>
                                    </Col>
                                    <Col md={6}>
                                      <div className={`${pm.formBox} d-flex gap-3`}>
                                        <label className="form-label">Trending</label>
                                        <Checkbox register={{ ...register("trending") }} />
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>

                        <Accordion>
                          <Accordion.Item eventKey="1">
                            <Accordion.Header>Sorting Order </Accordion.Header>
                            <Accordion.Body>
                              <Row>
                                <Col md={4}>
                                  <div className={pm.formBox}>
                                    <label className="form-label">Order </label>
                                    <select className="form-select" {...register("sort",)}>
                                      <option value="">--Select--</option>
                                      <option value="location">Location</option>
                                      <option value="priority">Priority</option>
                                      <option value="name">Name</option>
                                    </select>
                                  </div>
                                </Col>

                              </Row>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md={12} lg={10}>
                      <div className={`${cx.addAccordiaon_features}`}>
                        <Accordion>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>Select Business</Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <Row>
                                  <Col lg={2} md={3} className="px-2">
                                    <div className={cx.price_categorySelect}>
                                      <div className={`${pm.formBox} mb-0`}>
                                        <label className="form-label">Sequence Number</label>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col lg={6} md={6} className="px-2">
                                    <Col lg={11}>
                                      <div className={cx.price_categorySelect}>
                                        <div className={`${pm.formBox} mb-0`}>
                                          <label className="form-label">Assign Business</label>
                                        </div>
                                      </div>
                                    </Col>
                                  </Col>
                                  <Col lg={2} md={3} className="px-2">
                                    <div className={cx.price_categorySelect}>
                                      <div className={pm.formBox}>
                                        <label className="form-label">Business ID number</label>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>

                                {actionArray?.map((item: any, i: number) => {
                                  return (
                                    <Row className="align-items-center" key={i}>
                                      <Col lg={2} md={3} className="px-2">
                                        <div className={cx.price_categorySelect}>
                                          <div className={pm.formBox}>
                                            <label className="form-label">{item?.sq}</label>
                                          </div>
                                        </div>
                                      </Col>
                                      <Col lg={6} md={6} className="px-2">
                                        <Col lg={11}>
                                          <div className={cx.price_categorySelect}>
                                            <div className={pm.formBox} >
                                              <select
                                                className="form-select"
                                                value={item?.businessId}
                                                onChange={(e: any) => handleAddCat(i, e.target.value, "businessId")}
                                                style={errorTrig && (item?.businessId === "" || item?.error) ? { border: "1px solid red" } : {}}
                                              >
                                                <option value="">Select Business</option>
                                                {filterBusiness?.map((business: any) => {
                                                  return (
                                                    <option value={business?._id} key={business?._id}>{business?.businessName}</option>
                                                  )
                                                })}
                                              </select>

                                            </div>
                                          </div>
                                        </Col>
                                      </Col>
                                      <Col lg={3} md={3} className="px-2">
                                        <div className={cx.price_categorySelect}>
                                          <div className={pm.formBox}>
                                            <div>{item?.businessAccountNo}</div>
                                          </div>
                                        </div>
                                      </Col>
                                      <Col >
                                        <button className={cx.addBtn} onClick={() => handleAddRemoveHandler(i === 0 ? "inc" : "dec", i, "business")}>{i === 0 ? "+" : "-"}</button>
                                      </Col>
                                    </Row>
                                  )
                                })}
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </Col>
                  </Row>
                </>}
                {sectionType && sectionType === 'category' && (
                  <Row className="mb-3">
                    <Col md={12}>
                      <div className={`${cx.addAccordiaon_features}`}>
                        <Accordion>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>Select Categories</Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <Row>
                                  <Col lg={2} md={3} className="px-2">
                                    <div className={cx.price_categorySelect}>
                                      <div className={`${pm.formBox} mb-0`}>
                                        <label className="form-label">Sequence Number</label>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col lg={6} md={6} className="px-2">
                                    <Col lg={11}>
                                      <div className={cx.price_categorySelect}>
                                        <div className={`${pm.formBox} mb-0`}>
                                          <label className="form-label">Assign Categories</label>
                                        </div>
                                      </div>
                                    </Col>
                                  </Col>
                                </Row>

                                {actionArray?.map((item: any, i: number) => {
                                  return (
                                    <Row className="align-items-center" key={i}>
                                      <Col lg={2} md={3} className="px-2">
                                        <div className={cx.price_categorySelect}>
                                          <div className={pm.formBox}>
                                            <label className="form-label">{item?.sq}</label>
                                          </div>
                                        </div>
                                      </Col>
                                      <Col lg={6} md={6} className="px-2">
                                        <Col lg={11}>
                                          <div className={cx.price_categorySelect}>
                                            <div className={pm.formBox} >
                                              <select
                                                className="form-select"
                                                style={errorTrig && (item?.categoryId === "" || item?.error) ? { border: "1px solid red" } : {}}
                                                value={item?.categoryId}
                                                onChange={(e: any) => handleAddCat(i, e.target.value, "categoryId")}
                                              >
                                                <option value="">Select Category</option>
                                                {businessCat?.filter((cat: any) => actionArray?.some((action: any) => action?.sq === item?.sq ? true : action?.categoryId !== cat?._id))?.map((item: any) => {
                                                  return (
                                                    <option value={item?._id} key={item?._id}>{item?.BusinessCategoryName}</option>
                                                  )
                                                })}

                                              </select>

                                              {/* {errorMessage && <p className="errorMessage">{errorMessage}</p>} */}
                                            </div>
                                          </div>
                                        </Col>
                                      </Col>
                                      <Col >
                                        <button className={cx.addBtn} onClick={() => handleAddRemoveHandler(i === 0 ? "inc" : "dec", i, "category")}>{i === 0 ? "+" : "-"}</button>
                                      </Col>
                                    </Row>
                                  )
                                })}
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </Col>
                  </Row>
                )}
                {sectionType && sectionType === "marquee" && sectionType !== "banner" && sectionType !== 'category' && sectionType !== "search" && <>
                  <Row className="mt-2">
                    <Col xl={3} lg={4} md={6} >
                      <div className={cx.price_categorySelect}>
                        <div className={pm.formBox}>
                          <label className="form-label" htmlFor="Color Code 1">background code 1</label>
                          <input type="text" id="Color Code 1" className="form-control" placeholder="background code 1" {...register("colorCodePrimary")} />

                        </div>
                      </div>
                    </Col>
                    <Col xl={3} lg={2} md={3} >
                      <div className={cx.price_categorySelect}>
                        <div className={pm.formBox}>
                          <label className="form-label" htmlFor="Color Code 2">background color 2</label>
                          <input type="text" id="Color Code 2" className="form-control" placeholder="background color 2" {...register("colorCode")} />
                        </div>
                      </div>
                    </Col>
                    <Col xl={3} lg={2} md={3} >
                      <div className={cx.price_categorySelect}>
                        <div className={pm.formBox}>
                          <label className="form-label" htmlFor="backgroundColor">title color code</label>
                          <input type="text" id="backgroundColor" className="form-control" placeholder="background code"  {...register("bgCode")} />
                        </div>
                      </div>
                    </Col>
                    <Col xl={3} lg={2} md={3} >
                      <div className={cx.price_categorySelect}>
                        <div className={pm.formBox}>
                          <label className="form-label" htmlFor="text color code">text color code</label>
                          <input type="text" id="text color code" className="form-control" placeholder="text color code" {...register("textCode")} />
                        </div>
                      </div>
                    </Col>

                  </Row>
                  <Row>
                    <Col xl={4} lg={3} md={6} className="px-2">
                      <div className={cx.price_categorySelect}>
                        <div className={pm.formBox}>
                          <label className="form-label" htmlFor="desc">Description</label>
                          <textarea className="form-control" id="desc" rows={6} cols={4} {...register("description")}></textarea>

                        </div>
                      </div>
                    </Col>
                    <Col xl={3} lg={3} md={4} className="px-2">
                      <div className={cx.price_categorySelect}>
                        <div className={pm.formBox}>
                          <label className="form-label">Upload image</label>
                          <div className={`${st.uploadForm}`}>
                            <div className={`${st.fileUpload} ${st.pageUpload} `}>
                              {!imageagain && !editData?.image && <input
                                type="file"
                                onChange={(event) => {
                                  handleImageChange(event);
                                }}
                              />}
                              {!imageagain && !editData?.image ? (
                                <img src={uploadFile} className={`${st.icon}`} alt="Icon" />
                              ) : (
                                <div className={cx.imageBox}>
                                  <img src={icon} className={`${st.icon}`} alt="Icon" />
                                  <label htmlFor="upload" className={cx.uploadLabel}>
                                    <input
                                      type="file"
                                      id="upload"
                                      onChange={(event) => {
                                        handleImageChange(event);
                                      }}
                                    />
                                    <img src={uploadIcon} alt="" className={cx.uploadVector} />
                                  </label>
                                </div>
                              )}

                            </div>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row className="mb-3">
                    <Col md={12}>
                      <div className={`${cx.addAccordiaon_features}`}>
                        <Accordion>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>Select Categories</Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <Row>
                                  <Col lg={2} md={3} className="px-2">
                                    <div className={cx.price_categorySelect}>
                                      <div className={`${pm.formBox} mb-0`}>
                                        <label className="form-label">Sequence Number</label>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col lg={6} md={6} className="px-2">
                                    <Col lg={11}>
                                      <div className={cx.price_categorySelect}>
                                        <div className={`${pm.formBox} mb-0`}>
                                          <label className="form-label">Assign Categories</label>
                                        </div>
                                      </div>
                                    </Col>
                                  </Col>
                                </Row>

                                {actionArray?.map((item: any, i: number) => {
                                  return (
                                    <Row className="align-items-center" key={i}>
                                      <Col lg={2} md={3} className="px-2">
                                        <div className={cx.price_categorySelect}>
                                          <div className={pm.formBox}>
                                            <label className="form-label">{item?.sq}</label>
                                          </div>
                                        </div>
                                      </Col>
                                      <Col lg={6} md={6} className="px-2">
                                        <Col lg={11}>
                                          <div className={cx.price_categorySelect}>
                                            <div className={pm.formBox} >
                                              <select
                                                className="form-select"
                                                style={errorTrig && (item?.categoryId === "" || item?.error) ? { border: "1px solid red" } : {}}
                                                value={item?.categoryId}
                                                onChange={(e: any) => handleAddCat(i, e.target.value, "categoryId")}
                                              >
                                                <option value="">Select Category</option>
                                                {businessCat?.filter((cat: any) => actionArray?.some((action: any) => action?.sq === item?.sq ? true : action?.categoryId !== cat?._id))?.map((item: any) => {
                                                  return (
                                                    <option value={item?._id} key={item?._id}>{item?.BusinessCategoryName}</option>
                                                  )
                                                })}

                                              </select>

                                              {/* {errorMessage && <p className="errorMessage">{errorMessage}</p>} */}
                                            </div>
                                          </div>
                                        </Col>
                                      </Col>
                                      <Col >
                                        <button className={cx.addBtn} onClick={() => handleAddRemoveHandler(i === 0 ? "inc" : "dec", i, "category")}>{i === 0 ? "+" : "-"}</button>
                                      </Col>
                                    </Row>
                                  )
                                })}
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </Col>
                  </Row>
                </>}

                {sectionType && sectionType === "collection" && sectionType !== "banner" && sectionType !== 'category' && sectionType !== "search" && <>
                  <Row className="mt-3">
                    <Col md={12} lg={10}>
                      <div className={`${cx.addAccordiaon_features}`}>
                        <Accordion>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>Select Collections</Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <Row>
                                  <Col lg={2} md={3} className="px-2">
                                    <div className={cx.price_categorySelect}>
                                      <div className={`${pm.formBox} mb-0`}>
                                        <label className="form-label">Sequence Number</label>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col lg={6} md={6} className="px-2">
                                    <Col lg={11}>
                                      <div className={cx.price_categorySelect}>
                                        <div className={`${pm.formBox} mb-0`}>
                                          <label className="form-label">Assign Collection</label>
                                        </div>
                                      </div>
                                    </Col>
                                  </Col>

                                </Row>

                                {actionArray?.map((item: any, i: number) => {
                                  return (
                                    <Row className="align-items-center" key={i}>
                                      <Col lg={2} md={3} className="px-2">
                                        <div className={cx.price_categorySelect}>
                                          <div className={pm.formBox}>
                                            <label className="form-label">{item?.sq}</label>
                                          </div>
                                        </div>
                                      </Col>
                                      <Col lg={6} md={6} className="px-2">
                                        <Col lg={11}>
                                          <div className={cx.price_categorySelect}>
                                            <div className={pm.formBox} >
                                              <select
                                                className="form-select"
                                                value={item?.collectionId}
                                                onChange={(e: any) => handleAddCat(i, e.target.value, "collectionId")}
                                                style={errorTrig && (item?.collectionId === "" || item?.error) ? { border: "1px solid red" } : {}}
                                              >
                                                <option value="">Select Collection</option>
                                                {collectionList?.map((collection: any) => {
                                                  return (
                                                    <option value={collection?._id} key={collection?._id}>{collection?.collectionName}</option>
                                                  )
                                                })}
                                              </select>

                                            </div>
                                          </div>
                                        </Col>
                                      </Col>

                                      <Col >
                                        <button className={cx.addBtn} onClick={() => handleAddRemoveHandler(i === 0 ? "inc" : "dec", i, "collection")}>{i === 0 ? "+" : "-"}</button>
                                      </Col>
                                    </Row>
                                  )
                                })}
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </Col>
                  </Row>
                </>}
                {sectionType && sectionType === "banner" && <>
                  <Row className="mt-3">
                    <Col md={12} lg={10}>
                      <div className={`${cx.addAccordiaon_features}`}>
                        <Accordion>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>Add Banners</Accordion.Header>
                            <Accordion.Body>
                              <div>
                                <Row>
                                  <Col lg={2} md={3} className="px-2">
                                    <div className={cx.price_categorySelect}>
                                      <div className={`${pm.formBox} mb-0`}>
                                        <label className="form-label">Sequence Number</label>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col lg={6} md={6} className="px-2">
                                    <Col lg={11}>
                                      <div className={cx.price_categorySelect}>
                                        <div className={`${pm.formBox} mb-0`}>
                                          <label className="form-label">Banners</label>
                                        </div>
                                      </div>
                                    </Col>
                                  </Col>
                                  <Col lg={2} md={2} className="px-2">
                                    <Col lg={11}>
                                      <div className={cx.price_categorySelect}>
                                        <div className={`${pm.formBox} mb-0`}>
                                          <label className="form-label">URL</label>
                                        </div>
                                      </div>
                                    </Col>
                                  </Col>

                                </Row>

                                {actionArray?.map((item: any, i: number) => {
                                  return (
                                    <Row className="align-items-center mb-2" key={i}>
                                      <Col lg={2} md={3} className="px-2">
                                        <div className={cx.price_categorySelect}>
                                          <div className={pm.formBox}>
                                            <label className="form-label">{item?.sq}</label>
                                          </div>
                                        </div>
                                      </Col>
                                      <Col lg={6} md={6} className="px-2">
                                        <Col lg={11}>
                                          <div className={cx.price_categorySelect}>
                                            <div className={`${st.uploadForm} w-100`}>
                                              <div className={`${st.fileUpload} ${st.pageUpload} w-100`}>
                                                {!item?.bannerUrl && <input
                                                  type="file"
                                                  onChange={(event) => {
                                                    handleBanner(event, i);
                                                  }}
                                                />}
                                                {!item?.bannerUrl ? (
                                                  <img src={uploadFile} className={`${st.icon}`} style={{ objectFit: 'contain' }} alt="Icon" />
                                                ) : (
                                                  <div className={cx.imageBox}>
                                                    <img src={crossIcon} className={cx.deleteICon} onClick={() => handleBanner("delete", i)} alt="delete" />
                                                    <img src={item?.bannerUrl} className={`${st.icon}`} style={{ objectFit: 'contain' }} alt="Icon" />
                                                    <label htmlFor="upload" className={cx.uploadLabel}>
                                                      <input
                                                        type="file"
                                                        id="upload"
                                                        onChange={(event) => {
                                                          handleBanner(event, i);
                                                        }}
                                                      />
                                                      <img src={uploadIcon} alt={"banner"} className={cx.uploadVector} />
                                                    </label>
                                                    <button className="close_img">
                                                      <IoIosCloseCircle />
                                                    </button>
                                                  </div>
                                                )}

                                              </div>
                                            </div>
                                          </div>
                                        </Col>
                                      </Col>


                                      <Col lg={2} md={3}>
                                        <div className={cx.price_categorySelect}>
                                          <div className={pm.formBox}>
                                            <input type="text" placeholder="url" value={item?.url} className="form-control" onChange={(e: any) => handleAddCat(i, e.target.value, "url")} />
                                          </div>
                                        </div>
                                      </Col>
                                      <Col lg={1} md={2}>
                                        <button className={cx.addBtn} onClick={() => handleAddRemoveHandler(i === 0 ? "inc" : "dec", i, "banner")}>{i === 0 ? "+" : "-"}</button>
                                      </Col>
                                    </Row>
                                  )
                                })}
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </Col>
                  </Row>
                </>}
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </section>
    </>
  );
};

export default AddBusinessPage;
